/** @jsx jsx */
import React, { useMemo } from 'react';
import { Container, Text, Flex, Box, useColorMode, jsx } from 'theme-ui';
import { Link } from 'gatsby';

import { TextF5 } from '../text-f5';
import { Row } from '../grid';
export const FontBlock = ({ size, block, idx, font }) => {
  const [mode] = useColorMode();

  const isNursery = block.link._type === 'nursery';

  return (
    <Link
      tabIndex={0}
      to={`/${block.link._type === 'typeface' ? 'type' : 'nursery'}/${block.link.slug.current}`}
      sx={{
        outline: 'none',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        transition: 'all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        backgroundColor: 'button.home_block.bg',
        borderTop: idx !== 0 && '1px solid',
        borderColor: idx !== 0 ? 'line' : 'none',
        textDecoration: 'none',

        '& .type': {
          transformOrigin: 'center  center',
          transition: 'all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        },
        '& .subtitle': {
          textTransform: 'uppercase',
          opacity: 0.6,
          transition: 'all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000)',
        },
        '&:hover, &:focus': {
          backgroundColor: 'button.home_block.bg_hover',
          '& .type': {
            color: `accent`,
            transform: 'scale(1.02)',
          },
          '& .subtitle': {
            color: 'button.home_block.text_hover',
            opacity: 1,
          },
        },

        ':focus-visible': {
          outline: '1px solid',
          outlineColor: 'accent',
          outlineOffset: '-1px',
        },
      }}
    >
      {block.promoMessage ? (
        <div
          sx={{
            position: 'absolute',
            mt: 3,
            top: [8, 8],
            right: ['auto', 8],
            left: [6, 'auto'],

            py: [4, 5],
            px: [7, 7, 10],
            backgroundImage: (t) => t.gradients.secondary,
            color: 'mono.0',
            textAlign: 'center',
            fontWeight: 700,
          }}
        >
          <Text>{block.promoMessage}</Text>
        </div>
      ) : isNursery ? (
        <div
          sx={{
            position: 'absolute',
            mt: 3,
            top: [8, 8],
            right: ['auto', 8],
            left: [6, 'auto'],
            py: [4, 5],
            px: [7, 7, 10],
            backgroundImage: (t) => t.gradients.tertiary,
            color: 'mono.0',
            textAlign: 'center',
            fontWeight: 700,
          }}
        >
          <Text>F5 Nursery</Text>
        </div>
      ) : null}
      <Container>
        <Box
          sx={{
            minHeight: ['40vh', '60vh'],
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Flex
            sx={{
              flex: 1,
              flexDirection: 'column',
              alignItems: ['start', 'center'],
              justifyContent: 'space-between',
              textAlign: ['left', 'center'],
              py: [8, 10],
            }}
          >
            <div sx={{ width: '100%', textAlign: ['left', 'center'] }}>
              <Text sx={{ display: ['none', 'block'] }} className="above subtitle">
                {block.styles}
              </Text>
            </div>
            <Row sx={{ py: [0, 12], pt: [8, 12] }}>
              <TextF5
                data-child="f5-text"
                className="type"
                variant={size === 'lg' ? `f5_landing_block_font` : 'f5_landing_block_font_sm'}
                font={font._id}
                sx={{ textAlign: ['left', 'center'] }}
              >
                {block.sample}
              </TextF5>
            </Row>
            <div sx={{ width: '100%', textAlign: ['left', 'center'] }}>
              <Text className="below subtitle">{block.scripts}</Text>
              <Text sx={{ display: ['block', 'none'] }} className="above subtitle">
                {block.styles}
              </Text>
            </div>
          </Flex>
        </Box>
      </Container>
    </Link>
  );
};
