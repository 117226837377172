/** @jsx jsx */
import React, { useMemo } from 'react';
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import { VisuallyHidden } from '../components/visually-hidden';
import GraphQLErrorList from '../components/graphql-error-list';
import { PromoBanner } from '../components/promo-banner';

import { Landing } from '../components/landing';

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    );
  }
  return (
    <>
      <SEO {...data.landing.seo} />
      <VisuallyHidden as="h1">Typefaces</VisuallyHidden>
      {data.landing.promoBanner && <PromoBanner {...data.landing.promoBanner} />}
      <Landing blocks={data.landing.blocks} />
    </>
  );
};

export const query = graphql`
  query LandingPageQuery {
    landing: sanityPageLanding {
      seo {
        ...SanityPageSeoFragment
      }
      promoBanner {
        ...SanityPromoBannerFragment
      }
      blocks {
        _key
        _type
        promoMessage
        sample
        styles
        scripts
        size
        font {
          ...SanityFontOpenTypeFragment
        }
        size
        styles
        scripts
        link {
          ... on SanityTypeface {
            id
            _type
            slug {
              current
              _type
            }
          }
          ... on SanityNursery {
            id
            _type
            slug {
              current
              _type
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
