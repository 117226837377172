/** @jsx jsx */
import { Container, Text, useColorMode, Box, jsx } from 'theme-ui';
import React from 'react';
import { Link } from 'gatsby';
import { Anchor } from '../link';
import { TextF5 } from '../text-f5';
import { useFontLoader } from '../../woff-provider/use-font-loader';

import { Grid, Row, Col, Stack } from '../grid';
import { AnimatePresence, motion } from 'framer-motion';
export const PromoBanner = ({ font, align, link, link_label, style, subtitle, title }) => {
  useFontLoader(font && font._id);

  const Parent = link ? (p) => <Anchor {...p} /> : React.Fragment;

  return (
    <section
      key="landing-promo-banner"
      sx={{
        py: [6, 7, 9],
        backgroundImage: (t) =>
          style == 'primary'
            ? t.gradients.primary
            : style === 'secondary'
            ? t.gradients.secondary
            : t.gradients.tertiary,
        color: 'mono.0',
        textAlign: align || 'center',
      }}
    >
      <Parent to={link}>
        <Container>
          {font ? (
            <TextF5
              font={font._id}
              as="h2"
              sx={{
                display: 'block',
                fontSize: [5, 6],
                fontWeight: '700',
                lineHeight: 1.5,
              }}
            >
              {title}
            </TextF5>
          ) : (
            <Text
              as="h2"
              sx={{
                display: 'block',
                fontSize: [4, 5, 6],
                fontWeight: '700',
                lineHeight: 1.5,
              }}
            >
              {title}
            </Text>
          )}
          {subtitle && (
            <Text as="p" sx={{ fontSize: [1] }}>
              {subtitle}
            </Text>
          )}
        </Container>
      </Parent>
    </section>
  );
};

export default PromoBanner;
