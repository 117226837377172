/** @jsx jsx */
import React, { useMemo } from 'react';
import { Text, Flex, Box, jsx } from 'theme-ui';

import { useFontLoader } from '../../woff-provider/use-font-loader';

import { FontBlock } from './font-block';

export const FontsList = ({ blocks }) => {
  const fonts = useMemo(() => blocks.map((block) => block.font._id), [blocks]);
  useFontLoader(fonts);

  return (
    <>
      {blocks.map((block, idx) => {
        return (
          <FontBlock
            key={block.font._id}
            block={block}
            idx={idx}
            font={block.font}
            size={block.size}
          />
        );
      })}
    </>
  );
};
